import { useEffect, useState } from 'react';

import '@fllite-fe/shared/src/stylesheets/font-face.css';
import '@fllite-fe/shared/src/stylesheets/react-toggle.css';
import 'react-day-picker/dist/style.css';
import '@fllite-fe/shared/yupConfig';

import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppProps } from 'next/app';

import { FlashMessages, OpsAuthWrapper, ProvideAnalytics, useSentry } from '@fllite-fe/shared';
import { GlobalStyles } from '@fllite-fe/shared/src/components/GlobalStyle';
import { muiTheme } from '@fllite-fe/shared/src/muiTheme';

interface Props extends AppProps {}

const App = ({ Component, pageProps }: Props) => {
	const [queryClient] = useState(() => new QueryClient());
	const [showChild, setShowChild] = useState(false);

	useSentry();

	useEffect(() => {
		setShowChild(true);
	}, []);

	if (!showChild || typeof window === 'undefined') {
		return <div />;
	}

	return (
		<QueryClientProvider client={queryClient}>
			<MuiThemeProvider theme={muiTheme}>
				<ProvideAnalytics>
					<FlashMessages>
						<OpsAuthWrapper>
							<GlobalStyles />
							<CssBaseline />
							<Component {...pageProps} />
						</OpsAuthWrapper>
					</FlashMessages>
				</ProvideAnalytics>
			</MuiThemeProvider>
		</QueryClientProvider>
	);
};

export default App;
